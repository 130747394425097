import { Box } from "@mui/material";
import { SortedPatternSet, getActivityImageResized } from "./DataHelpers";

export interface WeaponSetImageProps {
    set : SortedPatternSet;
}

const WeaponSetImage = ({ set }: WeaponSetImageProps) => {
    return (
        <Box className="weaponset-image-container">
            <Box className="weaponset-image" component="img" sx={{
                content: {
                    xs: `url(${getActivityImageResized(set.metadata, 1200)})`,
                    xl: `url(${getActivityImageResized(set.metadata, 600)})`
                }
            }}></Box>
        </Box>
    )
}

export default WeaponSetImage